import { object, string } from 'yup';

import {
  CITY_ADDRESS_FIELD,
  COUNTRY_CODE,
  POSTAL_CODE_ADDRESS_FIELD,
  REGION_ADDRESS_FIELD,
  STREET_ADDRESS_FIELD,
  LATIN_CHARACTERS_WITHOUT_NUMBERS_REGEX,
  LATIN_CHARACTERS_WITH_NUMBERS_REGEX,
  CONTAINT_NON_LATIN_CHARACTERS_ERROR_MESSAGE,
  CONTAINT_NON_LATIN_CHARACTERS_OR_NUMBERS_ERROR_MESSAGE,
} from '../../constants';

export const ChangeAddressSchema = object().shape({
  [COUNTRY_CODE]: string()
    .required('Required field'),
  [CITY_ADDRESS_FIELD]: string()
    .matches(LATIN_CHARACTERS_WITHOUT_NUMBERS_REGEX, CONTAINT_NON_LATIN_CHARACTERS_OR_NUMBERS_ERROR_MESSAGE)
    .required('Required field'),
  [STREET_ADDRESS_FIELD]: string()
    .matches(LATIN_CHARACTERS_WITH_NUMBERS_REGEX, CONTAINT_NON_LATIN_CHARACTERS_ERROR_MESSAGE)
    .required('Required field'),
  [REGION_ADDRESS_FIELD]: string()
    .matches(LATIN_CHARACTERS_WITH_NUMBERS_REGEX, CONTAINT_NON_LATIN_CHARACTERS_ERROR_MESSAGE),
  [POSTAL_CODE_ADDRESS_FIELD]: string()
    .required('Required field'),
});
