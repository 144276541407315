export const SELECT_TYPE = 'selectType';
export const CARDS_DETAILS = 'cardsDetails';
export const SUMMARY = 'summary';

// form values
export const CARD_HOLDER_FIELD = 'cardHolder';
export const DISPLAY_NAME_FIELD = 'displayName';
export const EXPIRY = 'expiry';
export const EXPIRY_MONTH = 'expiryMonth';
export const EXPIRY_YEAR = 'expiryYear';
export const CARD_PROFILE = 'cardProfile';
export const COMPANY_NAME = 'companyName';
export const ADDRESS_FIELD = 'address';

export const DELIVERY_ADDRESS_TYPE_SELECTION = 'deliveryAddressTypeSelection';
export const COUNTRY_CODE = 'countryCode';
export const CITY_ADDRESS_FIELD = 'city';
export const STREET_ADDRESS_FIELD = 'street';
export const REGION_ADDRESS_FIELD = 'region';
export const POSTAL_CODE_ADDRESS_FIELD = 'postalCode';
export const ADDRESS_TYPE = 'type';

export const CARD_TYPE_SWITCH = 'cardTypeSwitch';
export const ACCOUNT_TYPE = 'accountType';
export const CARD_TYPE_SELECTION = 'cardTypeSelection';
export const CARD_DETAILS_FORM = 'cardDetailsForm';
export const EXISTING_ACCOUNT = 'existingAccount';
export const NEW_ACCOUNT = 'newAccount';
export const ACCOUNT_SELECTION = 'accountSelection';

export const MONTH_NUMBERS = new Array(12)
  .fill(null)
  .map((_, index) => index + 1);

export const cardCreationStatusMap = {
  pending: 'pending',
  success: 'success',
  fail: 'declined',
};

export const deliveryAddressTypeMap = {
  companyAddress: 'company-address',
  cardholderAddress: 'cardholder-address',
  specifiedAddress: 'specified-address',
};

export const addressTypeOptions = [
  {
    value: deliveryAddressTypeMap.companyAddress,
    label: 'Registered company address',
  },
  {
    value: deliveryAddressTypeMap.cardholderAddress,
    label: 'Cardholder home address',
  },
  {
    value: deliveryAddressTypeMap.specifiedAddress,
    label: 'Specified address',
  },
];

export const newAccount = {
  displayName: 'New',
  currency: 'EUR',
};

export const MIN_EXPIRATION_DATE_MONTHS = 1;
export const MAX_EXPIRATION_DATE_YEARS = 3;

// TODO: stop using hardcoded values
export const corporateCountries = {
  AT: 'Austria',
  BE: 'Belgium',
  BG: 'Bulgaria',
  HR: 'Croatia',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  EE: 'Estonia',
  FI: 'Finland',
  FR: 'France',
  DE: 'Germany',
  GI: 'Gibraltar',
  GR: 'Greece',
  HU: 'Hungary',
  IS: 'Iceland',
  IM: 'Isle of man',
  IE: 'Ireland',
  IT: 'Italy',
  LV: 'Latvia',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MT: 'Malta',
  MC: 'Monaco',
  NL: 'Netherlands',
  NO: 'Norway',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SK: 'Slovakia',
  SI: 'Slovenia',
  ES: 'Spain',
  SE: 'Sweden',
  CH: 'Switzerland',
  GB: 'United Kingdom',
};

export const deliveryCountryCodes = Object.keys(corporateCountries);

export const deliveryCountryOptions = Object.keys(corporateCountries).map(code => ({
  value: code,
  label: corporateCountries[code],
}));

// TODO: stop using hardcoded values
export const cardProfileOptions = [
  {
    label: 'EUR Default',
    value: 'EUR Default',
  },
];

export const sidebarMode = {
  hidden: 'hidden',
  addNew: 'addNew',
  edit: 'edit',
};

export const tableRows = {
  name: 'name',
  cardName: 'card_name',
  deliverTo: 'deliver_to',
  dueDate: 'due_date',
};

export const MIN_COMPANY_NAME_LENGTH_THRESHOLD = 16;
export const DISPLAY_NAME_MAX_ALLOWED_LENGTH = 50;
export const DISPLAY_NAME_MAX_ALLOWED_LENGTH_ERROR_MESSAGE =
  `Max ${DISPLAY_NAME_MAX_ALLOWED_LENGTH} character limit`;
export const CARDHOLDER_REQUIRED_ERROR_MESSAGE =
  'Please select cardholder name to proceed';

export const CHANGE_ADDRESS_FORM = 'changeAddressForm';

export const LATIN_CHARACTERS_WITHOUT_NUMBERS_REGEX =
  /^[\p{Script_Extensions=Latin}\p{Punctuation}\p{Symbol}\p{Mark}\s]+$/u;
export const LATIN_CHARACTERS_WITH_NUMBERS_REGEX =
  /^[\p{Script_Extensions=Latin}\p{Number}\p{Punctuation}\p{Symbol}\p{Mark}\s]+$/u;

export const CONTAINT_NON_LATIN_CHARACTERS_ERROR_MESSAGE = 'Contains non-Latin characters';
export const CONTAINT_NON_LATIN_CHARACTERS_OR_NUMBERS_ERROR_MESSAGE = 'Contains non-Latin characters or numbers';
