const config = {
  publicUrl: '/portal',
  corporateSiteUrl: 'https://intergiro.com/',
  merchantPageUrl: 'https://intergiro.com/business-banking/merchant-services',
  salesEmail: 'sales@intergiro.com',
  apiDocumentationUrl: 'https://intergiro.github.io/developer-portal/2d/',
  businessTermsUrl: 'https://intergiro.com/business-terms',
  helpCenterUrl: 'https://help.intergiro.com/en',
  faqsUrl: 'https://intergiro.com/faqs',
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.intergiro.app',
  appStoreUrl: 'https://apps.apple.com/mt/app/intergiro-business-banking/id1506731309',
  intergiroMonitor: {
    scriptUrl: 'https://merchant.intergiro.com/ui',
    serverUrl: 'https://merchant.intergiro.com/v1',
  },
  featureFlags: {
    enableBeneficiariesTrustedFlow: false,
    disabledLocalCurrencies: '',
    disabledLocalCurrenciesByCountry: '',
  },
};

export default config;
